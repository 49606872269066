import styled from "styled-components";
import Img from "gatsby-image";

const Image = styled(Img)`
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  @media screen and (min-width: 960px) {
    max-width: 800px;
    max-height: 500px;
  }
`;

export default Image;

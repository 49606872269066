import * as React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Title from "../components/Title";
import Content from "../components/Content";
import Image from "../components/Image";
import { motion } from "framer-motion";
import SEO from "../components/SEO";

export const query = graphql`
  query StylistQuery {
    allSanityStylist(sort: { fields: order }) {
      edges {
        node {
          _createdAt
          id
          firstName
          lastName
          bio
          booking
          phone
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

// markup
function Stylists({ data }) {
  const { edges } = data.allSanityStylist;

  return (
    <>
      <SEO
        title="CLIPS Hair Studio | Our stylists love what they do and are here to make you look great!"
        description="Our stylists are passionate about what they do! Give us a call at 501-503-5133 to see who is right for you, or book directly online!"
        url="https://www.clips-hairstudio.com/stylists/"
      />
      <Content>
        <Title>Our Stylists</Title>
        {edges.map(({ node }, i) => (
          <React.Fragment key={node.id}>
            <Image
              className={(i + 1) % 2 === 0 ? "even" : "odd"}
              fluid={node.image.asset.fluid}
              alt={`${node.firstName} ${node.lastName} headshot`}
            />
            <div>
              <h3>{`${node.firstName} ${node.lastName}`}</h3>
              <p>{node.bio}</p>
              <ButtonWrap>
                {node.booking ? (
                  <BookingButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.05 }}
                    to={`/book-online/${node.slug.current}`}
                    state={{
                      name: `${node.firstName} ${node.lastName}`,
                      booking: node.booking,
                    }}
                  >
                    Book Online with {node.firstName}
                  </BookingButton>
                ) : (
                  <CallButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.05 }}
                    onTap={{ scale: 1.05 }}
                    href={`tel:${node.phone}`}
                  >
                    Call to Book with {node.firstName}
                  </CallButton>
                )}
              </ButtonWrap>
            </div>
          </React.Fragment>
        ))}
      </Content>
    </>
  );
}

export default Stylists;

const ButtonWrap = styled.div`
  text-align: center;
  margin: 2rem 0 2rem 0;
`;

const BookingButton = styled(motion.custom(Link))`
  display: inline-block;
  background: var(--primary-color);
  margin: 0 auto;
  padding: 0.75em 1em;
  color: white;
  text-decoration: none;
  border-radius: 0.25em;
`;
const CallButton = styled(motion.a)`
  display: inline-block;
  background: var(--primary-color);
  margin: 0 auto;
  padding: 0.75em 1em;
  color: white;
  text-decoration: none;
  border-radius: 0.25em;
`;

import styled from "styled-components";

const Content = styled.div`
  position: relative;
  padding: 0 2rem;
  top: var(--nav-height);
  padding-bottom: 2rem;
  @media screen and (min-width: 960px) {
    display: grid;
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 2rem;
    grid-auto-flow: row dense;
    grid-template-areas: 1fr 1fr;
    grid-gap: 2rem;
    justify-items: center;
    align-items: center;
    p:nth-of-type(odd) {
      grid-column: 2/3;
    }
    p:nth-of-type(even) {
      grid-column: 1/2;
    }
    img:nth-of-type(odd) {
      grid-column: 1/2;
    }
    img:nth-of-type(even) {
      grid-column: 2/3;
    }
    .odd {
      grid-column: 1/2;
    }
    .even {
      grid-column: 2/3;
    }
  }
`;

export default Content;
